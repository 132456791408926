<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>课堂</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/checkCourse' }" >打卡课程</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-form
                :rules="rules"
                ref="form"
                :model="form"
                style="padding: 48px 72px"
                label-position="right"
                label-width="auto"
        >
            <el-form-item label="课程名称: " prop="name">
                <el-input
                        v-model="form.name"
                        style="width: 240px"
                        autofocus
                        clearable
                />
            </el-form-item>

            <el-form-item label="类型" prop="type">
                <el-radio-group v-model="form.type" size="mini">
                    <el-radio-button :label="1">视频</el-radio-button>
                    <el-radio-button :label="2">图文</el-radio-button>
					<el-radio-button :label="3">题库</el-radio-button>
					<el-radio-button :label="4">批注</el-radio-button>
					<el-radio-button :label="5">音频</el-radio-button>
					<el-radio-button :label="6">习题</el-radio-button>
					<el-radio-button :label="7">表达</el-radio-button>
					<el-radio-button :label="81">好词</el-radio-button>
					<el-radio-button :label="82">好句</el-radio-button>
					<el-radio-button :label="9">知识扩展</el-radio-button>
                    <el-radio-button :label="10">朗读</el-radio-button>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="排序" prop="sort">
                <el-input v-model="form.sort" style="width: 240px" />
            </el-form-item>

            <template v-if="form.type === 1">
                <el-form-item label="选择视频：" prop="video_id">
                    <selectVideo @onSelect="onSelect" @onDelete="onDelete"></selectVideo>
                </el-form-item>

                <el-form-item label="视频封面：" prop="pic">
                    <el-image :src="form.pic" class="videoImage" v-if="form.pic" />
                </el-form-item>
            </template>
			
			<template v-else-if="form.type === 3">
				<el-form-item label="选择题库: " prop="video_id">
					<editQuestionBank :questionBankName="questionBankName" @onSelect="onSelectItem" @onDelete="onDeleteItem"></editQuestionBank>
				</el-form-item>
			</template>
			
			<template v-else-if="form.type === 4">
				<el-form-item label="选择批注: " prop="video_id">
					<editAnnotation :annotationTitle="annotationTitle" @onSelect="onSelectItem" @onDelete="onDeleteItem"></editAnnotation>
				</el-form-item>
			</template>
			
			<template v-else-if="form.type === 5">
				<el-form-item label="选择音频: " prop="video_id">
					<editAudio :name="audioName" @onSelect="onSelectItem" @onDelete="onDeleteItem"></editAudio>
				</el-form-item>
				<el-form-item label="封面图" prop="pic">
					<edit-cover :pic="imageUrl+form.pic" @success="uploadSuccess"></edit-cover>
				</el-form-item>
				<el-form-item label="音频内容" prop="content">
					<el-input
					  resize="none"
					  type="textarea"
					  :rows="10"
					  style="width: 40%;"
					  v-model="form.content"
					  placeholder="请输入音频内容">
					</el-input>
				</el-form-item>
			</template>

            <el-form-item label="状态: " prop="status">
                <el-switch
                        :active-value="1"
                        :inactive-value="0"
                        v-model="form.status"
                        style="width: 240px"
                />
            </el-form-item>
			<template v-if="form.type === 7">
				<el-radio-group v-model="expression" class="margin-sm" @change="changeExpression">
				    <el-radio :label="1">视频</el-radio>
				    <el-radio :label="2">图文</el-radio>
				</el-radio-group>
				<blockquote v-if="expression === 1">
					<el-form-item label="选择视频：" prop="video_id">
					    <selectVideo @onSelect="onSelect" @onDelete="onDelete"></selectVideo>
					</el-form-item>
					
					<el-form-item label="视频封面：" prop="pic">
					    <el-image :src="form.pic" class="videoImage" v-if="form.pic" />
					</el-form-item>
				</blockquote>
				<el-form-item label="内容" prop="content" v-if="expression === 2">
					<editor :content="form.content" @onChange="onChange" />
				</el-form-item>
				<el-form-item label="解析" prop="analyse">
					<el-input
					  type="textarea"
					  :rows="5"
					  :maxlength="255"
					  :show-word-limit="true"
					  resize="no"
					  style="width:40%"
					  placeholder="请输入内容"
					  v-model="form.analyse">
					</el-input>
				</el-form-item>
			</template>

			<template v-if="form.type === 81">
				<el-form-item label="选择好词好句" prop="video_id">
					<editKnowledge1 :knowledgeTitle="knowledgeTitle1" @onSelect="onSelectItem" @onDelete="onDeleteItem"></editKnowledge1>
				</el-form-item>
			</template>

			<template v-if="form.type === 82">
				<el-form-item label="选择好词好句" prop="video_id">
					<editKnowledge1 :knowledgeTitle="knowledgeTitle1" @onSelect="onSelectItem" @onDelete="onDeleteItem"></editKnowledge1>
				</el-form-item>
			</template>

			<template v-if="form.type === 9">
				<el-form-item label="选择知识扩展" prop="video_id">
					<editKnowledge2 :knowledgeTitle="knowledgeTitle2" @onSelect="onSelectItem" @onDelete="onDeleteItem"></editKnowledge2>
				</el-form-item>
			</template>
            <template v-if="form.type === 2">
                <el-form-item label="课程内容" prop="content">
                    <editor :content="form.content" @onChange="onChange" />
                </el-form-item>
            </template>
            <template v-if="form.type === 10">
                <el-form-item label="音频内容" prop="content">
                    <editor :content="form.content" @onChange="onChange" />
                </el-form-item>
            </template>
			
            <el-form-item>
                <el-button size="mini" @click="$router.back()">取消</el-button>
                <el-button size="mini" @click="submit" type="primary">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import selectVideo from '@/components/selectVideo'
    import editor from '@/components/editor'
	import editQuestionBank from '@/components/editQuestionBank'
	import editAnnotation from '@/components/editAnnotation'
	import editAudio from '@/components/editAudio'
	import editKnowledge1 from '@/components/editKnowledge1'
	import editKnowledge2 from '@/components/editKnowledge2'
    import { config } from '@/js/config'
	import editCover from '@/components/editCover'
    import { mapActions } from 'vuex'

    export default {
        data: () => ({
            loading: false,
            config,

            rules: {
                name: [{ required: true, message: '请输入课程名称', trigger: 'blur' }],
                type: [{ required: true }],
                status: [{ required: true }],
                pic: [{ required: true }],
                notification: [{ required: true }],
                amount: [{ required: true }],
                indate: [{ required: true }],
                content: [{ required: true }],
				analyse:[{required: true}],
                sort: [{ required: true }],
                video_id: [{ required: true }]
            },

            options: [],
            selectGrade: [],
            form: {
                id:0,
                name: '',
                pic: '',
                status: 1,
                check_course_plan_id: '',
                size: '',
                sort: 0,
                duration: '',
                video_id: '',
                type: 1,
                content: '',
				analyse: ''
            },
			questionBankName: '',
			annotationTitle: '',
			audioName: '',
			knowledgeTitle1: '',
			knowledgeTitle2: '',
			imageUrl: config.imageUrl,
			expression: 1
        }),

        components: { selectVideo, editor,editQuestionBank , editAnnotation, editAudio,editCover,editKnowledge1,editKnowledge2},

        methods: {
            ...mapActions('punch', ['addPunch', 'addCheck', 'getPunchDetail']),

            onChange(content) {
                this.form.content = content
            },
            submit() {
                this.$refs.form.validate(async valid => {
                    if (!valid) return
                    else {
                        this.loading = true
                        if (this.form.type === 2) {
                            delete this.form.size
                            delete this.form.duration
                            delete this.form.video_id
                        }

                        const { id } = this.$route.query

                        const params = { ...this.form }

						if([81,82].includes(this.form.type)){
							if(this.form.type === 81) params.knowledge_type = 1
							if(this.form.type === 82) params.knowledge_type = 2
							params.type = 8
						}

                        if (id) params.id = id

                        const result = await this.addCheck(params)

                        this.loading = false

                        this.$message.success('修改成功')

                        this.$router.back()
                    }
                })
            },

            onSelect({ size, pic, id, duration }) {
                this.form.size = size
                this.form.pic = pic
                this.form.video_id = id
                this.form.duration = duration
            },

            uploadSuccess(path) {
                this.form.pic = path
            },

            onDelete() {
                this.form.size = ''
                this.form.pic = ''
                this.form.video_id = ''
                this.form.duration = ''
            },
			
			// 题库
			onSelectItem(id){
				this.form.video_id = id
			},
			onDeleteItem(){
				this.form.video_id = ''
			},
			// 表达
			changeExpression(val){
				// 更改成图文
				if(Number(val) === 2){
					this.form.video_id = 0
					this.form.pic = ''
				}else{
					this.form.content = ''
				}
			}
        },

        async mounted() {
            this.loading = true
            this.form.id = this.$route.params.id
            await this.getPunchDetail(this.form.id).then(res => {
                this.form.name = res.data.name
                this.form.pic = res.data.pic
                this.form.status = res.data.status
                this.form.check_course_plan_id = res.data.check_course_plan_id
                this.form.size = res.data.size
                this.form.sort = res.data.sort
                this.form.duration = res.data.duration
                this.form.video_id = res.data.video_id
                this.form.type = res.data.type
                this.form.knowledge_type = res.data.knowledge_type

                this.form.content = res.data.content
				this.form.analyse = res.data.analyse
				if(res.data.volume != undefined && res.data.volume !== null){
					this.questionBankName = res.data.volume.name
				}
				if(res.data.annotation != undefined && res.data.annotation !== null){
					this.annotationTitle = res.data.annotation.title
				}
				if(res.data.audio != undefined && res.data.audio !== null){
					this.audioName = res.data.audio.name
				}
				if(res.data.knowledge1 != undefined && res.data.knowledge1 !== null){
					this.knowledgeTitle1 = res.data.knowledge1.title
				}
				if(res.data.knowledge2 != undefined && res.data.knowledge2 !== null){
					this.knowledgeTitle2 = res.data.knowledge2.title
				}
				if(res.data.video_id !== 0){
					this.expression = 1
				}else{
					this.expression = 2
				}

				if(this.form.type === 8){
					if(this.form.knowledge_type === 1) this.form.type = 81
					if(this.form.knowledge_type === 2) this.form.type = 82
				}

            }).finally(() =>{
                this.loading = false
            })
        }
		
    }
</script>
