<template>
    <div>
        <div class="select-file-info" v-show="isReelect === false">
            <div class="select-file-info-name" >{{knowledgeTitle}}</div>
            <div class="select-file-info-option">
                <el-link type="primary" @click="reelectFile">重选</el-link>
            </div>
        </div>
		<selectKnowledge2 ref="video" v-show="isReelect === true" @onSelect="onSelect" @onDelete="onDelete"></selectKnowledge2>
	</div>
</template>

<script>
    import selectKnowledge2 from "./selectKnowledge2";
    export default {
        name: "editKnowledge2",
        components: {selectKnowledge2},
        props:{
            knowledgeTitle:{
                default:'',
                type:String
            },
        },
        data(){
            return {
                isReelect:false
            }
        },
        watch:{
            videoName(val){
                console.log(val)
            }
        },
        methods:{
            reelectFile(){
                this.isReelect = true
                console.log(this.$refs.video.$el.getElementsByClassName('select-video').item(0).click())
                this.$emit('reelect')
            },
            onSelect(video)
            {
                this.$emit('onSelect',video)
            },
            onDelete(e){
                this.$emit('onDelete',e)
            }
        }
    }
</script>

<style scoped>

</style>